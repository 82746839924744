/* eslint-disable no-useless-concat */
// eslint-disable-next-line import/prefer-default-export
export const homeHero = {
  title: "Home decor POS system and software",
  subtext:
    "Sell in-store and online from one easy-to-use system. Keep clients coming back with integrated marketing, loyalty, and online review monitoring tools.",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/retail/home-decor/demo",
  },
};
export const homeSubHero = {
  title: "Increase sales with flexible tech that adapts to your needs",
  list: [
    "Save time and avoid duplicate work with one catalog for online and in-store sales",
    "Reach more customers online with a custom ecommerce storefront and built-in tools for shipping and local delivery",
    "Engage your clients with built-in marketing, review management, and reporting tools",
    "Offer easier, contactless ways to collect payments for a better shopping experience",
  ],
  subtext:
    "Attract more shoppers to your store—both in-person and online—and simplify operations with the help of a robust retail platform that adapts to your business needs. Our platform is designed to save you time and simplify your job, that way you can focus on providing your clients with a memorable experience that keeps them coming back for more.",
  ctaPrime: {
    ctaTitle: "Learn more",
    ctaTarget: "/retail/home-decor/demo",
  },
};

export const homeWhiteGlove = {
  mainTitle: "Personalized service & support",
  title: "Your 24/7 on-call tech team",
  content:
    "You know how important it is to provide top-notch service to your clients. We’re here to do the same for you. Our expert team will set you up with a personalized solution to help you grow your home decor business. From building your custom product and services catalog to helping you set up your new point-of-sale device, we’ll be there with you every step of the way. Plus, you can count on our local service and technical support 24/7—anytime you need a helping hand.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/retail/home-decor/demo",
  },
};

export const homeLargeFeatureA = {
  featureBlocks: [
    {
      blockTitle: "One platform. More opportunities to sell",
      blockSubTitle:
        "Your time is valuable. Don’t waste it updating multiple catalogs and toggling between systems that don’t work together. SpotOn’s cloud-based tools are designed to work in harmony with one another, saving you time, generating new sales opportunities, and creating a seamless experience for your clients. ",
      blockImg: "home-a.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/retail/home-decor/demo",
      },
    },
  ],
};

export const homeTrusted = [
  {
    title: "72%",
    subtext:
      "of Americans prefer to buy from an independent store over a national retailer if equally convenient and reliable",
  },
  {
    title: "78%",
    subtext:
      "of Americans prefer to purchase from a retailer offering both in-person and online options",
  },
  {
    title: "68%",
    subtext:
      "of Americans prefer to buy gifts from an independent store instead of a national retailer if delivered to their doorstep",
  },
  {
    title: "73%",
    subtext:
      "of Americans would buy more last-minute gifts online from independent stores if they had guaranteed delivery dates",
  },
];

export const homeMultiSection = {
  sectionTitle: "Finely-crafted tools to run & grow your home decor business",
  featureBlocks: [
    {
      blockTitle: "In-store. Online. All in one",
      blockSubTitle:
        "Easily create and update your catalog in real-time for selling both in-store and online—from one online dashboard. SpotOn Retail gives you the flexibility to sell products as well as services, such as home installations or consulting services. Publish them to your online store, if you want one, and to your point-of-sale device. It’s easy for you and easy for your clients, whether they’re browsing in-store or from the comfort of their couch.",
      blockList: [
        "Easy POS catalog navigation for fast in-store checkout",
        "Client-friendly online shopping",
        "Multiple ways for customers to pay, including Apple Pay and Google Pay",
        "Customer data capture for marketing",
        "Online dashboard for transaction data & analytics",
      ],
      blockImg: "home-b.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/retail/home-decor/demo",
      },
    },
    {
      blockTitle: "Sell online with curbside, delivery, and national shipping",
      blockSubTitle:
        "Online browsing is the new window shopping. Get in on the action and start selling your home decor products and services online. Our ecommerce team will build you a custom online storefront in that includes built-in tools for high-demand services like national shipping, next day delivery, and in-store pickup.",
      blockImg: "home-d.png",
      blockList: [
        "Offer same-day delivery with our affordable, flat-fee DoorDash integration",
        "Provide in-store pickup and local 2-day delivery options",
        "Ship nationwide with the lowest shipping rates available",
        "Optional loyalty rewards to help boost repeat business",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/retail/home-decor/demo",
      },
    },
    {
      blockTitle: "Create stronger client relationships",
      blockSubTitle:
        "Keep your clients up to date with the newest trends, send coupons for overstocked inventory, and see who your top spenders are. With SpotOn, you can do it all with a single cloud-based dashboard, right from your mobile phone or online.",
      blockList: [
        "Send digital coupons",
        "Send marketing emails",
        "Create Facebook campaigns",
      ],
      blockImg: "home-c.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/retail/home-decor/demo",
      },
    },
    {
      blockTitle: "Reward your best clients",
      blockSubTitle:
        "Increase profits by rewarding your clients with attractive incentives to drive repeat visits. Our integrated point-of-sale makes it fast and easy to enroll during checkout, earn “Spots” with every purchase, and redeem them for custom rewards of your choice. It’s a proven way to boost client retention and grow revenue.",
      blockImg: "home-e.jpg",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/retail/home-decor/demo",
      },
    },
    {
      blockTitle: "Manage and improve your online reputation",
      blockSubTitle:
        "Your clients' opinions matter. Online reviews can help you reach new clients—or scare them away. SpotOn makes it easy to stay on top of your online reviews from sites like Yelp, Facebook, and Google, so you can improve your online reputation and expand your online reach.",
      blockList: [
        "Track and see all your online reviews in one place",
        "Get notified every time you get a new review",
        "Easily shift to review sites to respond",
        "Watch your reputation improves with time",
      ],
      blockImg: "home-f.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/retail/home-decor/demo",
      },
    },
  ],
};

export const homeCTA = {
  title: "Book your consultation.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/retail/home-decor/demo",
  },
};

export const homeTestimonials = {
  title: "What other business owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const homeArticles = {
  title: "Expert articles & advice on growing your business",
  bgText: "Articles.",
};

export const hairCar = {
  title: "Run a different type of retail business",
  subtext: "Home decor stores are just one of our specialties.",
  punctuation: "?",
  slides: [
    {
      bg: "clothing.png",
      cardTitle: "Clothing & Apparel",
      cardContent: "",
      icon: "clothing-icon.png",
      linkTo: "/retail/clothing-and-apparel",
    },
    {
      bg: "sporting.png",
      cardTitle: "Sporting Goods",
      cardContent: "",
      icon: "sporting.png",
      linkTo: "/retail/sporting-goods",
    },
  ],
};
