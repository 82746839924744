import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import BHero from "../../../components/Hero/b-hero";
import {
  homeHero,
  homeSubHero,
  homeWhiteGlove,
  homeLargeFeatureA,
  homeTrusted,
  homeMultiSection,
  homeCTA,
  homeTestimonials,
  homeArticles,
  // homeFaqs,
  hairCar,
} from "../../../data/subverticals/retail/home-decor-data";
// import NoVisualsCustom from "../../../components/NoVisuals/NoVisualsCustom";
// import WhiteGlove from "../../../components/WhiteGlove/WhiteGlove";
// import LargeFeatures from "../../../components/LargeFeatures/LargeFeatures";
// import TrustedNumbers from "../../../components/TrustedNumbers/TrustedNumbers";
// import LargeCta from "../../../components/CTAs/LargeCta";
// import TestmonialReviews from "../../../components/TestimonialReviews/TestmonialReviews";
// import BusinessTypes from "../../../components/BusinessTypes/BusinessTypes";
// import Articles from "../../../components/Articles/Articles";
// import VideoSection from "../../../components/VideoSection/VideoSection";
import heroBg from "../../../images/hero-images/home-decor-hero.png";
import { retailOverviewVideo } from "../../../data/subverticals/retail/retail-overview-data";

const NoVisualsCustom = loadable(() =>
  import("../../../components/NoVisuals/NoVisualsCustom")
);
const WhiteGlove = loadable(() =>
  import("../../../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/LargeFeatures")
);
const TrustedNumbers = loadable(() =>
  import("../../../components/TrustedNumbers/TrustedNumbers")
);
const LargeCta = loadable(() => import("../../../components/CTAs/LargeCta"));
const TestmonialReviews = loadable(() =>
  import("../../../components/TestimonialReviews/TestmonialReviews")
);
const BusinessTypes = loadable(() =>
  import("../../../components/BusinessTypes/BusinessTypes")
);
const Articles = loadable(() =>
  import("../../../components/Articles/Articles")
);
const VideoSection = loadable(() =>
  import("../../../components/VideoSection/VideoSection")
);

const whiteGloveImport = "home-white-glove.jpg";

const HomeDecor = () => {
  return (
    <Layout>
      <SEO
        title="Home Decor POS Systems & Software for Retailers | SpotOn"
        description="SpotOn is the best home decor retail point of sale system available. Check-out customers with ease online from one easy-to-use system."
        image={`https://spoton.com/${heroBg}`}
      />
      <BHero sectionData={homeHero} heroBg="home-decor-hero.jpg" />
      <NoVisualsCustom sectionData={homeSubHero} complex />
      <WhiteGlove
        sectionData={homeWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <LargeFeatures
        sectionData={homeLargeFeatureA}
        style={{ marginTop: "128px" }}
      />
      <TrustedNumbers title="By the numbers" numbersArray={homeTrusted} />
      <LargeFeatures
        sectionData={homeMultiSection}
        style={{ marginTop: "128px !important" }}
      />
      <VideoSection sectionData={retailOverviewVideo} />
      <LargeCta sectionData={homeCTA} />
      {/* <SuccessStories sectionData={heroSuccess} slides={hbBTSlides} /> */}
      <TestmonialReviews sectionData={homeTestimonials} />
      <Articles
        sectionData={homeArticles}
        blogFilters={{
          limit: 3,
          include: "tags,authors",
          filter: "tag:small-business",
        }}
        cornerStone={false}
        style={{ marginTop: 60 }}
      />
      <BusinessTypes sectionData={hairCar} />
      {/* <Faqs sectionData={homeFaqs} /> */}
    </Layout>
  );
};

export default HomeDecor;
